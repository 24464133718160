import { combineReducers } from '@reduxjs/toolkit'
import data from './dataSlice'
import dataHistory from './dataHistorySlice'
import state from './stateSlice'

const reducer = combineReducers({
    data,
    dataHistory,
    state
})

export default reducer