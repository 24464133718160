import ApiService from "./ApiService";

export async function apiGetEditProfile(data) {
  return ApiService.fetchData({
    url: "/v1/portal/profile",
    method: "get",
  });
}

export async function apiUpdateProfile(data) {
  return ApiService.fetchData({
    url: "/v1/portal/profile/update",
    method: "post",
    data,
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
}
