import { url } from "constants/url.constant";
import ApiService from "./ApiService";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: url.login,
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: url.register,
    method: "post",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: url.logout,
    method: "post",
    headers: {
      Authorization: `Bearer ${data}`,
    },
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/v1/forgot-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/v1/reset-password",
    method: "post",
    data,
  });
}

export async function apiVerifyToken(data) {
  return ApiService.fetchData({
    url: "/v1/portal/auth/verify/"+data,
    method: "post",
  });
}
