import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetDashboardAdmin, apiGetDashboardUser } from 'services/DashboardService'


export const getDataAdmin = createAsyncThunk('dashboard/admin', async (token) => {
    const response = await apiGetDashboardAdmin(token)
    return response.data
})

export const getDataUser = createAsyncThunk('dashboard/user', async (token) => {
    const response = await apiGetDashboardUser(token)
    return response.data
})



export const initialState = {
    data: [],
    loading: true
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getDataUser.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        [getDataUser.pending]: (state) => {
            state.loading = true
        },
        [getDataAdmin.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        [getDataAdmin.pending]: (state) => {
            state.loading = true
        },
    }
})


export default dashboardSlice.reducer