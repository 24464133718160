import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState, getDataUser } from "store/auth/userSlice";
import { apiSignIn, apiSignOut, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { apiGetEditProfile } from "services/ProfileService";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        // console.log(resp.data.data.role)
        const token = resp.data.access_token;
        dispatch(onSignInSuccess(token));
        if (resp.data.data) {
          dispatch(setUser(resp.data.data));
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: resp.data.message,
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message:
          errors?.response?.data?.message || errors?.response?.data?.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        if (resp.data.status === 0) {
          // console.log('kesalahan: ', resp.data.errors);
          const errors = resp.data.errors;
          // let arr = []
          // for (const key in errors) {
          // 	// arr.push(errors[key])
          // 	arr = key
          // }
          return {
            status: "failed",
            message: errors,
          };
        }
        // const token = resp.data.access_token
        // dispatch(onSignInSuccess(token))
        // if (resp.data.data) {
        // 	dispatch(setUser(resp.data.data))
        // }
        // const redirectUrl = query.get(REDIRECT_URL_KEY)
        // navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
        return {
          status: "success",
          message: resp.data.message,
        };
      }
    } catch (errors) {
      return {
        status: "error",
        message:
          errors?.response?.data?.message || errors?.response?.data?.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async (values) => {
    await apiSignOut(values);
    handleSignOut();
  };

  const refresh = async () => {
    try {
      const resp = await apiGetEditProfile();
      if (resp.data) {
        if (resp.data.data) {
          dispatch(setUser(resp.data.data));
        }
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
    refresh,
  };
}

export default useAuth;
