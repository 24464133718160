import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'historyList/state',
    initialState: {
        selectedCategory: '',
        sortedColumn: () => { },
    },
    reducers: {
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload
        },
    },
})

export const {
    setSortedColumn,
    setSelectedCategory
} = stateSlice.actions

export default stateSlice.reducer