import React from 'react'
import { HiOutlineMenuAlt2, HiOutlineMenu, HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi'

const NavToggle = ({ toggled, className }) => {
	return (
		<div className={className}>
			{toggled ? <HiChevronDoubleRight /> : <HiChevronDoubleLeft />}
		</div>
	)
}

export default NavToggle