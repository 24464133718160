import ApiService from "./ApiService";

export async function apiGetDashboardUser(token) {
  return ApiService.fetchData({
    url: "/v1/portal/dashboard/data",
    method: "get",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export async function apiGetDashboardAdmin(token) {
  return ApiService.fetchData({
    url: "/v1/portal-admin/dashboard/data",
    method: "get",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
