import ApiService from "./ApiService";
import { url } from "constants/url.constant";

export async function apiGetListPocket(pageSize, token) {
  return ApiService.fetchData({
    url: url.get_pocket_list,
    method: "get",
    params: { paginate: pageSize },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function apiGetListHistory(params) {
  return ApiService.fetchData({
    url: "/v1/portal/pocket/history",
    method: "get",
    params,
  });
}

export async function apiPostTopUp(data) {
  return ApiService.fetchData({
    url: "/v1/portal/pocket/topup",
    method: "post",
    data,
  });
}
