const BASE_URL = process.env.REACT_APP_SERVER;
const BASE_URL_ADMIN = process.env.REACT_APP_SERVER_ADMIN;

export const url = {
  // Auth
  login: `${BASE_URL}/v1/portal/auth/login`,
  register: `${BASE_URL}/v1/portal/auth/register`,
  logout: `${BASE_URL}/v1/portal/auth/logout`,

  // Global
  get_data_user: `${BASE_URL}/v1/portal/profile`,
  get_pocket_list: `${BASE_URL}/v1/portal/pocket/data`,

  // User
  edit_profile_user: `${BASE_URL}/v1/portal/profile/edit`,
  get_portal_dashboard: `${BASE_URL}/v1/portal/dashboard/data`,

  // Admin dan Support
  edit_profile_admin: `${BASE_URL}/v1/portal-admin/profile/edit`,
  get_portal_admin_dashboard: `${BASE_URL}/v1/portal-admin/dashboard/data`,
};
