import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiGetListHistory } from "services/PocketService"


export const getHistoryTopUp = createAsyncThunk('historyList/data/getHistory', async (data) => {
    const response = await apiGetListHistory(data)
    return response.data
})

export const initialTableData = {
    total: 0,
    page: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialFilterData = {
    name: '',
    status: '',
}

const dataHistorySlice = createSlice({
    name: 'historyList/data',
    initialState: {
        loading: false,
        historyList: [],
        tableData: initialTableData,
        filterData: initialFilterData
    },
    reducers: {
        updateHistoryList: (state, action) => {
            state.historyList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        }
    },
    extraReducers: {
        [getHistoryTopUp.fulfilled]: (state, action) => {
            state.historyList = action.payload.data.data
            state.tableData.total = action.payload.data.total
            state.loading = false
        },
        [getHistoryTopUp.pending]: (state) => {
            state.loading = true
        }
    }
})

export const { updateHistoryList, setTableData, setFilterData } = dataHistorySlice.actions

export default dataHistorySlice.reducer